import React, { type FunctionComponent, useState } from 'react';
import { marked } from 'marked';
import { Divider } from 'bb/common/Divider';
import { ToggleRow } from 'bb/common/ToggleRow';
import { type FaqItem } from 'bb/common/types';
import { Text } from 'bb/i18n';
import { Box, CircleArrowRightIcon } from 'bb/ui';
import css from './faqListItem.module.scss';

marked.use();
type FaqListItemProps = {
    item: FaqItem;
};
export const FaqListItem: FunctionComponent<FaqListItemProps> = ({ item }) => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <>
            <ToggleRow
                header={
                    <Text as="h3" size="large" marginBottom="none">
                        <Text>{item.question}</Text>
                    </Text>
                }
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                useIcon
                justifyContent="spaceBetween"
                alignItems="center"
                customIcon={CircleArrowRightIcon}
            >
                <Box paddingTop="extraSmall">
                    <div
                        className={css.text}
                        // eslint-disable-next-line react/no-danger
                        dangerouslySetInnerHTML={{
                            __html: marked.parse(item.answer)
                        }}
                    />
                </Box>
            </ToggleRow>
            <Divider />
        </>
    );
};
