import React, { type FC } from 'react';
import { Gap, List, ListItem } from 'bb/ui';
import { MaxWidthContainer } from '../Containers';
import { FaqListItem } from './FaqListItem';
import { type FaqProps } from './types';

export const FaqList: FC<FaqProps> = ({ faq }) => (
    <MaxWidthContainer maxWidth={800}>
        <List>
            <Gap spacing={5}>
                {faq?.[0]?.items?.map((q) => (
                    <ListItem key={q.question}>
                        <FaqListItem item={q} />
                    </ListItem>
                ))}
            </Gap>
        </List>
    </MaxWidthContainer>
);
