import React, { type FC } from 'react';
import { HeaderSection } from 'bb/discovery/components/HeaderSection';
import { useTranslation } from 'bb/i18n';
import { Block } from '../components';
import { FaqList } from './FaqList';
import { FaqText } from './FaqText';
import { type FaqProps } from './types';

export const Faq: FC<FaqProps> = ({
    faq,
    faqText = <FaqText />,
    ...restProps
}) => {
    useTranslation('faq');

    return (
        <HeaderSection
            title="faq:faq"
            heading="faq:header"
            subHeading={{ children: faqText }}
            {...restProps}
        >
            <Block withPagePadding>
                <FaqList faq={faq} />
            </Block>
        </HeaderSection>
    );
};
