/* eslint-disable react/no-danger */
import React from 'react';
import { localhostbbnextweb } from './scripts/localhostbbnextweb';
import { prodbbnextweb } from './scripts/prodbbnextweb';
import { prodbbwebapp } from './scripts/prodbbwebapp';
import { testbbnextweb } from './scripts/testbbnextweb';
import { testbbwebapp } from './scripts/testbbwebapp';

const scripts: Record<string, string> = {
    'Test.BB.WebApp': testbbwebapp,
    'Test.BB.NextWeb': testbbnextweb,
    'Prod.BB.NextWeb': prodbbnextweb,
    'Prod.BB.WebApp': prodbbwebapp,
    'Localhost.BB.NextWeb': localhostbbnextweb
};

const appName = process.env.NEW_RELIC_APP_NAME;
const nrConfigScript = (appName && scripts[appName]) || '';

export const NewRelic = () =>
    nrConfigScript ? (
        <script
            dangerouslySetInnerHTML={{
                __html: nrConfigScript
            }}
        />
    ) : null;
