/* eslint-disable react/no-danger */
import React, { type ReactNode } from 'react';

export const TagManager = ({ market }: { market: string }): ReactNode => {
    if (!process.env.TAG_MANAGER_CODE) return null;

    return (
        <script
            crossOrigin="anonymous"
            dangerouslySetInnerHTML={{
                __html: `
            (function(w,l,g){
                w[l] = w[l] || [];
                w[g] = function(){
                  w[l].push(arguments);
                }
                w[l].push({
                  'bb_market': '${market}'
                });
                w[g]('consent', 'default', {
                    'ad_storage': 'denied',
                    'ad_user_data': 'denied',
                    'ad_personalization': 'denied',
                    'analytics_storage': 'denied',
                    'functionality_storage': 'denied',
                    'personalization_storage': 'denied',
                    'security_storage': 'denied'
                  });
              })(window, 'dataLayer', 'gtag');
              (function(w,d,s,l,i){
                w[l]=w[l]||[];
                w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});
                var f=d.getElementsByTagName(s)[0],
                j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';
                j.async=true;
                j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;
                f.parentNode.insertBefore(j,f);
              })(window,document,'script','dataLayer','${process.env.TAG_MANAGER_CODE}');
        `
            }}
        />
    );
};

export const TagManagerNoScript = (): ReactNode => (
    <noscript
        dangerouslySetInnerHTML={{
            __html: `<iframe src="//www.googletagmanager.com/ns.html?id=${process.env.TAG_MANAGER_CODE}" height="0" width="0" style="display:none;visibility:hidden;"></iframe>`
        }}
    />
);
