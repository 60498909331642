import React, { type FC } from 'react';
import { ExternalLink } from 'bb/common/ExternalLink';
import { Text, useTranslation } from 'bb/i18n';

export const FaqText = () => <Text t="faq:text" center />;

interface FaqTextStudentProps {
    pdfLink: string;
}
export const FaqTextStudent: FC<FaqTextStudentProps> = ({ pdfLink }) => {
    const { t } = useTranslation();
    return (
        <Text t="student:studentFaqInfo" center>
            <ExternalLink target="_blank" href={t('studentV2:faqLink')} />
            <ExternalLink target="_blank" href={pdfLink} />
        </Text>
    );
};
