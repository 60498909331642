import React, { type FC } from 'react';
import { Text, useTranslation } from 'bb/i18n';
import { Box } from 'bb/ui/Box';
import { Notification } from 'bb/ui/Notification';
import css from './notification.module.scss';

export const GeneralAlert: FC = () => {
    useTranslation('alert');
    return (
        <Box className={css.sticky}>
            <Notification absolute type="alert" padding="small" closeIcon>
                <Text t="alert:general" />
            </Notification>
        </Box>
    );
};
