import React, { useCallback, useEffect, useState } from 'react';
import Script from 'next/script';
import { useIsLoggedIn } from 'bb/account';
import { useRouter } from 'bb/app/router';
import { useFeature } from 'bb/config/features';
import { Cookies } from 'bb/cookies/config';
import { getCookieValue } from 'bb/cookies/utils';
import { isProductionEnvironment } from 'bb/utils/environment';

export const ActivateOptimizelyScript = () => {
    const enabled = useFeature('optimizely');
    const { router } = useRouter();
    const isLoggedIn = useIsLoggedIn();
    const [previousPath, setPreviousPath] = useState(router.asPath);
    const oneTrustCookie = getCookieValue(Cookies.ONETRUST_COOKIE);

    // C0002 together with box closed means that performance cookies are accepted
    const hasAcceptedPerformanceCookies = oneTrustCookie?.includes('C0002:1');
    const optimizelySrc = isProductionEnvironment()
        ? 'https://cdn.optimizely.com/js/23989151467.js'
        : 'https://cdn.optimizely.com/js/24209110056.js';

    useEffect(() => {
        // We dont use this effect at all currently.
        // Maby we need this to ignore query param changes if triggering 'activate' on every route change is considered bad practice.
        const current = router.asPath;
        const prev = previousPath;
        if (current !== prev) {
            setPreviousPath(current);
        }
    }, [router.asPath, previousPath]);

    useEffect(() => {
        const handleRouteChangeComplete = () => {
            if (window?.optimizely) {
                // Sends the activate push to the Optimizely snippet
                const success = window.optimizely?.push?.({
                    type: 'activate'
                });
                if (!isProductionEnvironment()) {
                    // eslint-disable-next-line no-console
                    console.info(
                        `%cPush optimizely activate event: ${success}`,
                        'color: red;'
                    );
                }
            }
        };

        router.events.on('routeChangeComplete', handleRouteChangeComplete);
        return () => {
            router.events.off('routeChangeComplete', handleRouteChangeComplete);
        };
    }, [router.events]);

    const makeVisible = useCallback(() => {
        document.documentElement.style.setProperty(
            '--optimizely-visibility',
            'visible'
        );
    }, []);

    useEffect(() => {
        if (!enabled) makeVisible();
    }, [enabled, makeVisible]);

    useEffect(() => {
        if (window?.optimizely) {
            const attributeValue = isLoggedIn ? 'true' : 'false';
            window.optimizely.push({
                type: 'user',
                attributes: {
                    isLoggedIn: attributeValue
                }
            });
        }
    }, [isLoggedIn]);

    // Note that we will load this script in GTM if the user consents to performance cookies
    return enabled && hasAcceptedPerformanceCookies ? (
        <Script
            id="load-optimizely"
            onLoad={() => {
                window?.optimizely?.push({ type: 'activate' });
                makeVisible();
            }}
            onError={() => {
                makeVisible();
            }}
            strategy="afterInteractive"
            src={optimizelySrc}
            crossOrigin="anonymous"
        />
    ) : null;
};
