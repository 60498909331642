/**
 * Generic helper that finds the cheapest product in a list of products
 * and returns it. Works with a veriety of product types due to the
 * generic nature of the function.
 */
export const findCheapestProduct = <TProduct extends { price?: number }>(
    products?: TProduct[] | Readonly<TProduct[]> | null
): TProduct | null => {
    if (!products || products.length === 0) return null;

    let cheapestProduct: TProduct = products[0] as TProduct;

    products.forEach((product) => {
        /**
         * If the product has no price, we assign it to Infinity to avoid
         * it being selected as the cheapest product.
         */
        if ((product.price ?? Infinity) < (cheapestProduct.price ?? 0)) {
            cheapestProduct = product as TProduct;
        }
    });

    return cheapestProduct;
};
